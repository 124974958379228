/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Layout } from '@leshen/gatsby-theme-contentful'

import {
  Banner,
  Brandy,
  Billboard,
  SplitContent,
  VariableContent,
  Image,
  List,
  ListItem,
  LinkButton,
  ModalWithState,
  SVGImage,
  Accordion,
  PackageCard,
  LeshenPhoneCTA,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Stack, Columns, Column, Dropdown, Typography } from '@leshen/ui'
import { graphql, Link as GatsbyLink, navigate } from 'gatsby'

import SeoCtaBar from '../components/SeoCtaBar'
import getFullStateName from '../utils/getFullStateName'
import siteTheme from '../../theme'

const CityTemplate = ({ data }) => {
  const {
    Meta_Description,
    State,
    Promo_Code,
    Page_Path,
    Meta_Title,
    FAQ_Group,
    City,
  } = data.pageInfo.edges[0].node
  const { rotatedNumber } = useMapi()

  const capitalize = (string) =>
    string
      ?.split(' ')
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(' ')
  const stateName = getFullStateName(State.toUpperCase())
  const cityName = capitalize(City)
  const stateNameAbbreviation = State.toUpperCase()

  const brandyPackageList = [
    'wds-tier1a-internet3',
    'wds-tier1a-internet2',
    'wds-tier1a-internet1',
  ]

  const packageList = brandyPackageList
    .map((packageId) =>
      data.allContentfulPackage.edges.find(
        ({ node }) => node.identifier === packageId
      )
    )
    .filter((node) => node)

  const brandyPackageBundles = [
    'wds-tier1a-bundle1',
    'wds-tier1a-bundle2',
    'wds-tier1a-bundle3',
  ]

  const packageBundles = brandyPackageBundles
    .map((packageId) =>
      data.allContentfulPackage.edges.find(
        ({ node }) => node.identifier === packageId
      )
    )
    .filter((node) => node)

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: '',
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: '',
      },
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
      path: Page_Path,
      promoCode: Promo_Code,
      customBreadCrumbs: null,
    },
  }

  const FaqQuestions = {
    1: [
      {
        title: `What Windstream services are available in ${cityName}, ${stateNameAbbreviation}?`,
        content: (
          <div>
            <Typography>
              See which services are available in {cityName}, {stateName} by
              viewing the internet and phone package cards at the top of this
              page.
            </Typography>
          </div>
        ),
      },
      {
        title: `How much is it to get Windstream service in  ${cityName}, ${stateName}?`,
        content: (
          <div>
            <Typography>
              The price of Windstream packages varies based on what package you
              choose. Take a look at the package cards at the top of this page
              for more details.
            </Typography>
          </div>
        ),
      },
      {
        title: `How do I reach Kinetic by Windstream support in ${cityName}, ${stateNameAbbreviation}?`,
        content: (
          <div>
            <Typography>
              Speak with a Kinetic by Windstream support agent at{' '}
              <a
                href={`
              tel:${rotatedNumber}
              `}
              >
                {rotatedNumber}
              </a>
              .
            </Typography>
          </div>
        ),
      },
      {
        title: `Am I able to get home phone with Windstream internet in ${cityName}, ${stateNameAbbreviation}?`,
        content: (
          <div>
            <Typography>
              Yes, absolutely. For more information, view the Kinetic by
              Windstream Phone Services section on this page, or view our{' '}
              <a href="/packages">phone and internet packages</a> page.
            </Typography>
          </div>
        ),
      },
    ],
    2: [
      {
        title: `Does ${cityName}, ${stateNameAbbreviation} have Windstream internet services?`,
        content: (
          <div>
            <Typography>
              Yes, {cityName}, {stateName} has Windstream internet services. For
              more information about the speeds offered in your area, check out
              the package cards at the top of this page.
            </Typography>
          </div>
        ),
      },
      {
        title: `How much do internet and home phone packages cost in ${cityName}, ${stateName}?`,
        content: (
          <div>
            <Typography>
              Internet and home phone package prices depend on which plans you
              select. Take a look at the offers at the top of this page or visit
              our <a href="/packages">phone and internet packages</a> page for
              more information.
            </Typography>
          </div>
        ),
      },
      {
        title: `How do I contact Windstream in ${cityName}, ${stateNameAbbreviation}?`,
        content: (
          <div>
            <Typography>
              Contact Windstream customer support by calling{' '}
              <a
                href={`
              tel:${rotatedNumber}
              `}
              >
                {rotatedNumber}
              </a>
              .
            </Typography>
          </div>
        ),
      },
      {
        title: `What fiber internet bundles does Windstream offer?`,
        content: (
          <div>
            <Typography>
              Windstream offers several fiber internet packages, with speeds up
              to 1 Gig. Check out our{' '}
              <a href="/kinetic-internet/gig">Gig internet</a> page for more
              details.
            </Typography>
          </div>
        ),
      },
    ],
    3: [
      {
        title: `Is there fiber internet available in ${cityName}, ${stateNameAbbreviation}?`,
        content: (
          <div>
            <Typography>
              Yes, Windstream Fiber is available in {cityName}, {stateName}.
            </Typography>
          </div>
        ),
      },
      {
        title: `How much is it to get Windstream service in ${cityName}, ${stateName}?`,
        content: (
          <div>
            <Typography>
              Windstream service in {cityName}, {stateName} varies in cost,
              depending on what you order. Check out the package cards at the
              top of this page for more information.
            </Typography>
          </div>
        ),
      },
      {
        title: `What is the contact number for Windstream in ${cityName}, ${stateNameAbbreviation}?`,
        content: (
          <div>
            <Typography>
              The customer service number for Windstream in {cityName},{' '}
              {stateName} is{' '}
              <a
                href={`
              tel:${rotatedNumber}
              `}
              >
                {rotatedNumber}
              </a>
              .
            </Typography>
          </div>
        ),
      },
      {
        title: `Does Windstream offer bundles in ${cityName}, ${stateNameAbbreviation}?`,
        content: (
          <div>
            <Typography>
              Yes, Windstream offers phone and internet bundles in {cityName},{' '}
              {stateName}.
            </Typography>
          </div>
        ),
      },
    ],
    4: [
      {
        title: `Am I able to get Windstream Fiber Internet in ${cityName}, ${stateNameAbbreviation}`,
        content: (
          <div>
            <Typography>
              Yes, you can get Windstream Fiber in {cityName}, {stateName}.
            </Typography>
          </div>
        ),
      },
      {
        title: `How much does Windstream Internet cost in ${cityName}, ${stateName}?`,
        content: (
          <div>
            <Typography>
              The price of Windstream Internet depends on which service you
              order. Check out the package cards at the top of this page for
              more information.
            </Typography>
          </div>
        ),
      },
      {
        title: `What is the number for Windstream in ${cityName}, ${stateNameAbbreviation} to add a service?`,
        content: (
          <div>
            <Typography>
              The number to add a service in {cityName}, {stateName} is{' '}
              <a
                href={`
              tel:${rotatedNumber}
              `}
              >
                {rotatedNumber}
              </a>
              .
            </Typography>
          </div>
        ),
      },
      {
        title: `Does Windstream in ${cityName}, ${stateNameAbbreviation} offer bundles with internet?`,
        content: (
          <div>
            <Typography>
              Yes, you can bundle Windstream phone and internet in {cityName},{' '}
              {stateName}. Check out our{' '}
              <a href="/packages">phone and internet packages</a> page for more
              details.
            </Typography>
          </div>
        ),
      },
    ],
  }

  const cityDropdownData = data.cities.edges.map((current) => ({
    label: current.node.City,
    value: current.node.Page_Path,
  }))

  return (
    <Layout
      data={passedData}
      main={
        <>
          <Billboard
            customLink={GatsbyLink}
            variant="split"
            backgroundColor="dark"
            mainContent={
              <>
                <Typography variant="h1">
                  WINDSTREAM INTERNET IN {City?.toUpperCase()},{' '}
                  {State?.toUpperCase()}
                </Typography>
                <List style={{ marginBottom: '16px' }}>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography>No data caps</Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography>30-day money back guarantee</Typography>
                    </Typography>
                  </ListItem>
                </List>
                <LeshenPhoneCTA color="primary" gradient>
                  Call Now
                </LeshenPhoneCTA>
              </>
            }
            image={
              <Image
                data={data.heroImage.cloudinary[0].gatsbyImageData}
                isImageCritical
              />
            }
          />
          <Banner
            backgroundColor="secondary"
            mainContent={
              <>
                <Typography variant="h4">
                  Receive a $100 Kinetic Reward Prepaid Mastercard® with
                  Internet Purchase*
                </Typography>
                <ModalWithState modalText="*See offer conditions">
                  <Brandy text={data.bannerDisclaimer.text} />
                </ModalWithState>
              </>
            }
            image={<Image data={data.visaCard.cloudinary[0].gatsbyImageData} />}
          />
          <SeoCtaBar />
          <VariableContent
            backgroundColor="dark"
            style={{ backgroundColor: siteTheme.colors.light }}
            mainContent={
              <>
                <Typography variant="h2">
                  Kinetic by Windstream Internet for {cityName}, {stateName}{' '}
                  Residents
                </Typography>
                <Typography variant="h5">
                  Whether you work, learn, or play online, Windstream Internet
                  has the perfect plan for your internet needs.{' '}
                </Typography>
              </>
            }
            alignMainContent="center"
          >
            <Stack spacing="xxl">
              <Columns>
                {packageList.map((currentPackage) => (
                  <PackageCard
                    label={currentPackage.node.label}
                    packageData={{ ...currentPackage.node.brandy }}
                    key={currentPackage.node.contentful_id}
                    content={
                      <>
                        <LeshenPhoneCTA color="primary">
                          Order Today!{' '}
                        </LeshenPhoneCTA>
                      </>
                    }
                  />
                ))}
              </Columns>
            </Stack>
          </VariableContent>
          <VariableContent
            alignMainContent="center"
            mainContent={
              <>
                <Typography variant="h2">
                  Benefits of Kinetic Internet in {cityName}, {stateName}
                </Typography>
              </>
            }
          >
            <Stack spacing="xxl">
              <Columns leftAligned={false}>
                <Column
                  image={
                    <SVGImage
                      svg={data.lightningIcon.cloudinary[0].svg.code}
                      presentationWidth={
                        data.lightningIcon.cloudinary[0].svg.presentationWidth
                      }
                    />
                  }
                >
                  <Typography variant="h4">UNLIMITED INTERNET</Typography>
                  <Typography>
                    No data caps. Surf and stream worry free all week long.
                  </Typography>
                </Column>
                <Column
                  image={
                    <SVGImage
                      svg={data.infinityIcon.cloudinary[0].svg.code}
                      presentationWidth={
                        data.infinityIcon.cloudinary[0].svg.presentationWidth
                      }
                    />
                  }
                >
                  <Typography variant="h4">NO ANNUAL CONTRACTS</Typography>
                  <Typography>
                    No early termination fees or year-long contracts. Cancel
                    anytime.
                  </Typography>
                </Column>
                <Column
                  image={
                    <SVGImage
                      svg={data.moneyIcon.cloudinary[0].svg.code}
                      presentationWidth={
                        data.moneyIcon.cloudinary[0].svg.presentationWidth
                      }
                    />
                  }
                >
                  <Typography variant="h4">
                    30-DAY MONEY-BACK GUARANTEE
                  </Typography>
                  <Typography>
                    No risks. Love it for 30 days or get your money back.
                  </Typography>
                </Column>
              </Columns>
            </Stack>
          </VariableContent>
          <VariableContent
            backgroundColor="dark"
            style={{ backgroundColor: siteTheme.colors.light }}
            mainContent={
              <>
                <Typography variant="h2">
                  Get Kinetic by Windstream Phone Services in {cityName}
                </Typography>
                <Typography>
                  With Kinetic by Windstream Phone Services, get access to
                  unlimited calling, Call Waiting, SPAM call alerts, and Caller
                  ID.
                </Typography>
                <Typography variant="">
                  See which Kinetic Voice by Windstream Phone Service works best
                  for you.
                </Typography>
              </>
            }
            alignMainContent="center"
          >
            <Stack spacing="xxl">
              <Columns>
                {packageBundles.map((currentPackage) => (
                  <PackageCard
                    label={currentPackage.node.label}
                    packageData={{ ...currentPackage.node.brandy }}
                    key={currentPackage.node.contentful_id}
                    content={
                      <>
                        <LeshenPhoneCTA color="primary">
                          Order Today!{' '}
                        </LeshenPhoneCTA>
                      </>
                    }
                  />
                ))}
              </Columns>
            </Stack>
          </VariableContent>
          <VariableContent
            style={{
              paddingTop: 'calc(0vw + 48px)',
              paddingBottom: 'calc(0vw + 48px)',
            }}
            mainContent={
              <>
                <Typography variant="h2">
                  Cities in {stateName} That Offer Windstream Service
                </Typography>
                <Typography>
                  Windstream provides internet and phone services to many areas
                  within {stateName}. See if our services are available in your
                  area by checking the dropdown menu below.
                </Typography>
                <Dropdown
                  options={cityDropdownData}
                  onChange={(e) => navigate(e.value)}
                  placeholderText="Select City"
                />
                {cityDropdownData?.map((current) => (
                  <StyledLink href={current.value} key={current.value}>
                    {current.label}
                  </StyledLink>
                ))}
              </>
            }
            alignMainContent="center"
          />
          <SplitContent
            backgroundColor="dark"
            customLink={GatsbyLink}
            image={
              <Image
                data={data.womanAndDog.cloudinary[0].gatsbyImageData}
                isImageCritical
                alt="Man sitting on couch with his dog working on the computer"
              />
            }
            mainContent={
              <>
                <Typography variant="h2">Moving To {cityName}?</Typography>
                <Typography>
                  Whether you’re a new Windstream customer or you’re taking your
                  current Windstream Internet plan with you, we’ve got your back
                  every step of your moving journey.
                </Typography>
                <List>
                  <ListItem>
                    <Typography>
                      To order service at your new location, call a Windstream
                      agent at{' '}
                      <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a>.
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      For current customers, simply call{' '}
                      <a href="tel:1-800-347-1991">1-800-347-1991</a> to request
                      a service transfer and we’ll take care of the rest.
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      With no annual contracts, Windstream is a risk-free choice
                      for your new home internet.
                    </Typography>
                  </ListItem>
                </List>
                <LinkButton
                  to="/resources/windstream-moving-guide"
                  style={{ marginTop: '16px' }}
                  outlined
                  ghost
                >
                  Read Our Moving Guide
                </LinkButton>
              </>
            }
          />
          <VariableContent
            alignMainContent="center"
            mainContent={
              <>
                <Typography variant="h2">
                  RESOURCES FOR WINDSTREAM CUSTOMERS IN{' '}
                  {cityName?.toUpperCase()}
                </Typography>
              </>
            }
          >
            <Stack spacing="xxl">
              <Columns cardStyled leftAligned={false}>
                <Column
                  image={
                    <Image
                      hasMarginBottom
                      data={data.dadAndSon.cloudinary[0].gatsbyImageData}
                      alt="A dad and son playing video games together"
                    />
                  }
                >
                  <Typography variant="h4">Fiber vs. Cable</Typography>
                  <Typography>
                    Increase your internet know-how with Windstream’s guide to{' '}
                    <a href="/resources/fiber-vs-cable-internet/">
                      fiber and cable internet
                    </a>
                    .
                  </Typography>
                </Column>
                <Column
                  image={
                    <Image
                      hasMarginBottom
                      data={data.laptopBed.cloudinary[0].gatsbyImageData}
                      alt="A girl using a laptop in bed"
                    />
                  }
                >
                  <Typography variant="h4">MBPS VS. GBPS</Typography>
                  <Typography>
                    Learn the difference between{' '}
                    <a href="/resources/mbps-vs-gbps-internet/">
                      Mbps and Gig speeds
                    </a>{' '}
                    with this helpful guide.
                  </Typography>
                </Column>
                <Column
                  image={
                    <Image
                      hasMarginBottom
                      data={data.gamer.cloudinary[0].gatsbyImageData}
                      alt="A gamer playing video games"
                    />
                  }
                >
                  <Typography variant="h4">BEST SPEEDS FOR GAMING</Typography>
                  <Typography>
                    Find out the{' '}
                    <a href="/resources/internet-gaming-speeds/">
                      best speeds for your gaming
                    </a>{' '}
                    needs so you can avoid buffering and lag.
                  </Typography>
                </Column>
                <Column
                  image={
                    <Image
                      hasMarginBottom
                      data={data.videoBuffering.cloudinary[0].gatsbyImageData}
                      alt="A video on a phone buffering"
                    />
                  }
                >
                  <Typography variant="h4">Speed Test</Typography>
                  <Typography>
                    Our{' '}
                    <a href="/kinetic-internet/speed-test">
                      internet speed test
                    </a>{' '}
                    evaluates your current upload and download speeds.
                  </Typography>
                </Column>
              </Columns>
            </Stack>
          </VariableContent>
          <VariableContent
            backgroundColor="dark"
            mainContent={
              <Typography variant="h2">Frequently asked questions</Typography>
            }
            alignMainContent="center"
          >
            <Stack spacing="xxl" alignMainContent="center">
              <Accordion items={FaqQuestions[FAQ_Group]} />
            </Stack>
          </VariableContent>
        </>
      }
    />
  )
}

CityTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default CityTemplate

const StyledLink = styled.a`
  display: none !important;
`

export const query = graphql`
  query CityTemplateQuery($pagePath: String!, $stateAbbreviation: String!) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerGetwindstreamAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          FAQ_Group
          State
          City
          Promo_Code
          Page_Path
          Meta_Title
          Meta_Description
        }
      }
    }
    allContentfulHeader(
      filter: {
        identifier: {}
        id: { eq: "6a23be44-8383-5acd-89d2-ed8897bb9490" }
      }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "3917969e-1614-5d22-a749-99fee5972b07" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
    cities: allDatasetManagerGetwindstreamAoa(
      filter: { State: { eq: $stateAbbreviation }, Area_Type: { eq: "city" } }
    ) {
      edges {
        node {
          City
          Page_Path
        }
      }
    }
    heroImage: contentfulMedia(
      contentful_id: { eq: "2x9QHDlTUOLxrEx9rdSmA5" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    womanAndDog: contentfulMedia(
      contentful_id: { eq: "3nCTdbSM3IH8b7X8OZyaxA" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    gamer: contentfulMedia(contentful_id: { eq: "5kBt7zdZncYtYvLGHsbyG7" }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 200)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    dadAndSon: contentfulMedia(
      contentful_id: { eq: "4jxAKsERjG8HQbtzGtBe4J" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 200)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    laptopBed: contentfulMedia(
      contentful_id: { eq: "1UZeGT03BSboC3aJdzphmZ" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 200)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    visaCard: contentfulMedia(contentful_id: { eq: "1AmYFIwjFnhnKBDtFV63dp" }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 200)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    videoBuffering: contentfulMedia(
      contentful_id: { eq: "2eqDEtLnNPdiAmbX19mHMX" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 200)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    lightningIcon: contentfulMedia(
      contentful_id: { eq: "6N2R6ezglUhgBLGx771KLF" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    infinityIcon: contentfulMedia(
      contentful_id: { eq: "2eaxIDrqLI58yCD3IIuQ2g" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    moneyIcon: contentfulMedia(
      contentful_id: { eq: "3hLjfW7ggZGRb5vRZQRHBW" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    allContentfulPackage {
      edges {
        node {
          __typename # used to determine component to render
          id
          contentful_id # required to make association
          identifier # user created contentful identifier
          brandy {
            name
            details
            bullets {
              text
              disclaimer {
                text
                symbol
              }
            }
            price {
              currency
              sale
              amount
              duration
              prefix
              suffix
              before
              disclaimer {
                text
                symbol
              }
            }
          }
          label
        }
      }
    }
    bannerDisclaimer: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-visa-prepaid-card" }
    ) {
      symbol
      text
    }
  }
`
