import React from 'react'
import { ShoppingCart } from 'emotion-icons/material'
import {
  CtaBar,
  LinkButton,
  LeshenPhoneCTA,
  Typography,
  useMapi,
} from '@leshen/gatsby-theme-leshen'

const SeoCtaBar = ({ spanish }) => {
  const { sessionPromoCode, requestId } = useMapi()

  return (
    <CtaBar
      backgroundColor="darkGray"
      textColor="white"
      sticky
      leftBlock={
        <Typography variant="body">
          {spanish ? 'Ordena servicio hoy' : 'Order service today!'}
        </Typography>
      }
      middleBlock={
        <LinkButton
          leftIcon={spanish ? null : ShoppingCart}
          to={`https://shop.getwindstream.com/?kbid=${sessionPromoCode}}&clreqid=${requestId}`}
        >
          {spanish ? 'Ordena en línea' : 'Order'}
        </LinkButton>
      }
      rightBlock={
        <LeshenPhoneCTA outlined color="white">
          {spanish ? 'Llama ahora' : 'Call'}
        </LeshenPhoneCTA>
      }
    />
  )
}

export default SeoCtaBar
